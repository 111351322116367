import { Environment } from '@shared-libs/enums';

export const environment = {
	BASE_URL: 'https://staging.api.beego.be',
	ONESIGNAL: '249aa618-2591-4d66-8956-a079cc97ba70',
	production: false,
	environment: Environment.Staging,
	sentryDsn: 'https://68dce7d9913642b1bdc3b0e747c20687@sentry.beego.be/5',
	eventEngineUrl: 'wss://ws.event-engine.stag.beego.be',
	mapAccessToken: 'zyTdG32yqt0T6ncgVT1J5ScQfcQ91qU5OrwfOqO6NVkVP0QiNWJJgEKbIfIXChPB',
};
