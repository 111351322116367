import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { NavigationDataKey, NavigationType, ROLE } from '@shared-libs/enums';
import { cloneDeep, findLast, isArray, isString } from 'lodash';

import { App } from '@capacitor/app';
import { BehaviorSubject } from 'rxjs';
import { INavigationObject } from '@shared-libs/interfaces';
import { Injectable } from '@angular/core';
import { NavigationDataManager } from '@app/modules/shared/managers/navigation-data.manager';
import { Router } from '@angular/router';
import { UserManager } from '@shared-managers/user.manager';
import { ValidationService } from './validation.service';

enum RoleNavigationPrefix {
	Partner = 'partner',
	/** @deprecated */
	Client = 'client',
	Admin = 'admin',
	Consultant = 'consultant',
}

/**
 * The navigation service that handles navigation (forward and back) in the app
 *
 * We use a custom service to save a history to make sure we can navigate back
 * to the correct page, modal or popover.
 */
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private history: Array<INavigationObject> = new Array();
	private routeNavigationSubjects: Array<{
		route: string;
		subject: BehaviorSubject<{ current: INavigationObject; closing?: INavigationObject }>;
	}> = [];
	constructor(
		private readonly modalController: ModalController,
		private readonly popoverController: PopoverController,
		private readonly router: Router,
		private readonly navigationController: NavController,
		private readonly navigationDataManager: NavigationDataManager,
		private readonly userManager: UserManager,
		private readonly platform: Platform,
		private readonly validationService: ValidationService
	) {
		void this.platform
			.ready()
			.then(() => {
				if (this.validationService.isOnNativeAndroid()) {
					this.platform.backButton.subscribeWithPriority(1, () => {
						if (this.navigationStackIsEmpty()) {
							return App.exitApp();
						}
						void this.navigateBack();
					});
				}
			})
			.catch();
	}

	/**
	 * A method used to navigate to a component or page, that enables us to handle navigation data and and returning between closed modals
	 * @param {NavigationType} _type The type of the navigation to use
	 * @param _destination The destination to navigate to (either an url or a component)
	 * @param _data Data to pass to the component
	 * @param _addToHistory Whether to add the navigation parameters to the navigation history (ex. needed to return to a component)
	 * @returns A promise that returns possible return data
	 */
	public async navigateTo<Return = any>(
		_type: NavigationType,
		_destination: string | any,
		_data?: { [key in NavigationDataKey | 'componentProps']?: any },
		_addToHistory: boolean = true
	): Promise<Return> {
		const destination = this.addRolePrefix(_type, _destination);
		this.handleCurrentNavigationObject();
		this.addToHistory(_type, destination, _data, _addToHistory);
		this.addToNavigationData(_data);
		switch (_type) {
			case NavigationType.modal:
				return this.openModal<Return>(destination, _data?.componentProps);
			case NavigationType.popover:
				return this.openPopover<Return>(destination, _data?.clickEvent, _data?.componentProps);
			case NavigationType.page:
				await this.openPage(destination);
				this.emitNavigationToPage({ current: { type: _type, destination, data: _data } });
				return Promise.resolve(null) as Promise<Return>;
		}
	}

	/**
	 * A method used to navigate back to the previous component or page.
	 *
	 * When coming from a modal or popover, the modal or popover is closed
	 * @param data The data that needs to be passed back from a component or popover
	 */
	public async navigateBack(data?: { [key: string]: any } | boolean | string): Promise<void> {
		const current = this.history.pop();
		switch (current?.type) {
			case NavigationType.modal:
				if (await this.modalController.getTop()) {
					void this.modalController.dismiss(data).catch();
				} else {
					const interval = setInterval(async () => {
						if (await this.modalController.getTop()) {
							clearInterval(interval);
							void this.modalController.dismiss(data).catch();
						}
					});
				}
				break;
			case NavigationType.popover:
				if (await this.popoverController.getTop()) {
					void this.popoverController.dismiss(data).catch();
				} else {
					const interval = setInterval(async () => {
						if (await this.popoverController.getTop()) {
							clearInterval(interval);
							void this.popoverController.dismiss(data).catch();
						}
					});
				}
				break;
			default:
				break;
		}
		const previous = this.history[this.history.length - 1];
		if (previous && previous.type !== NavigationType.page && current.type !== NavigationType.page) {
			void this.navigateTo(previous.type, previous.destination, previous.data, false).catch();
		} else if (!previous) {
			if (!current || current.type === NavigationType.page) {
				this.navigationController.back();
			}
		} else {
			const previousPage = findLast(
				this.history,
				(navigationObject) => navigationObject.type === NavigationType.page
			) || { type: NavigationType.page, destination: '/' };
			void this.navigateTo(previousPage.type, previousPage.destination, previousPage.data, false).catch();
			if (previousPage !== previous) {
				void this.navigateTo(previous.type, previous.destination, previous.data, false).catch();
			}
		}
	}

	public isActive(_route: string): boolean {
		const route = this.addRolePrefix(NavigationType.page, _route);
		return this.router.isActive(route, {
			paths: 'subset',
			queryParams: 'subset',
			fragment: 'ignored',
			matrixParams: 'ignored',
		});
	}

	/**
	 * A method that returns the navigation data based on a key
	 * @param _key The key {@link NavigationDataKey} that links to data
	 * @returns The navigation data
	 */
	public getNavigationData<I = any>(_key: NavigationDataKey): I {
		return this.navigationDataManager.getData(_key);
	}

	public initializeNavigation(): void {
		this.addToHistory(NavigationType.page, this.router.url, null, true);
	}

	/**
	 * A method that resets the history chain of the navigation service
	 */
	public resetNavigation(): void {
		this.history = new Array();
	}

	/**
	 * A method that adds the navigation object to the navigation history
	 * @param {NavigationType} type The type of the navigation to use
	 * @param destination The destination to navigate to (either an url or a component)
	 * @param data Data to pass to the component
	 * @param addToHistory Whether to add the navigation parameters to the navigation history (ex. needed to return to a component)
	 */
	public addToHistory(
		type: NavigationType,
		destination: string | any,
		data?: { [key in NavigationDataKey]?: any },
		addToHistory: boolean = true
	): void {
		if (addToHistory && !this.isPrefixRoute(type, destination)) {
			this.history.push({
				type,
				destination: this.addRolePrefix(type, destination),
				data,
			});
		}
	}

	/**
	 * Subscribe to the navigate event when the application navigates to a page
	 * @returns The navigation details
	 */
	public onNavigateToCurrentPage(): BehaviorSubject<{ current: INavigationObject }> {
		const existingRouteSubject = this.routeNavigationSubjects.find(
			(routeNavigationSubject) => routeNavigationSubject.route === this.router.url
		);

		if (existingRouteSubject) {
			return existingRouteSubject.subject;
		}

		const routeSubject = { route: this.router.url, subject: new BehaviorSubject(null) };
		this.routeNavigationSubjects.push(routeSubject);
		return routeSubject.subject;
	}

	/**
	 * A check to see if the navigation stack is empty. We should always have a base entry in the navigation stack,
	 * so when we have one or less items in the history, this means the navigation stack is empty.
	 * @returns Whether or not the navigation stack is empty
	 */
	public navigationStackIsEmpty(): boolean {
		return this.history.length <= 1;
	}

	/**
	 * A method that returns the navigation path based on a string or array of strings including the role prefix
	 * @param segments The segments of the navigation path
	 * @returns The navigation path
	 */
	public getPageNavigationPath(segments: string | string[]): string {
		if (Array.isArray(segments)) {
			return this.addRolePrefix(NavigationType.page, `/${segments.join('/')}`);
		}

		return this.addRolePrefix(NavigationType.page, segments);
	}

	/**
	 * Emit navigation details to the subject of the current page
	 * @param navigation The navigation details
	 * @param navigation.current The current navigation object (of the current page)
	 */
	private emitNavigationToPage(navigation: { current: INavigationObject }): void {
		const existingRouteSubject = this.routeNavigationSubjects.find(
			(routeNavigationSubject) => routeNavigationSubject.route === this.router.url
		);

		if (existingRouteSubject) {
			existingRouteSubject.subject.next(navigation);
		}
	}

	/**
	 * A method that handles closing modals or popovers of the modal or popover when going to navigate to another component or page
	 */
	private handleCurrentNavigationObject(): void {
		const current = this.getCurrentNavigationObject();

		switch (current?.type) {
			case NavigationType.modal:
				void (current.modalInstance as HTMLIonModalElement)?.dismiss().catch();
				break;
			case NavigationType.popover:
				void (current.popoverInstance as HTMLIonPopoverElement)?.dismiss().catch();
				break;
		}
	}

	/**
	 * A method that navigates to a page based on an url
	 * @param _url The url to navigate to
	 */
	private async openPage(_url: string): Promise<void> {
		await this.router.navigate([_url], { onSameUrlNavigation: 'reload' }).catch();
	}

	/**
	 * A method that opens a modal based on a component
	 * @param _component The component to open as modal
	 * @param _componentProps Extra properties to pass to the component
	 * @returns A promise that returns data returned from the component
	 */
	private async openModal<Return>(_component: any, _componentProps: any = {}): Promise<Return> {
		return new Promise(async (resolve) => {
			const modal = await this.modalController.create({
				component: _component,
				cssClass: `auto-height ${this.userManager.isConsultant() ? 'mobile-only' : ''}`,
				backdropDismiss: false,
				componentProps: _componentProps,
			});
			void modal
				.onDidDismiss()
				.then((result) => resolve(result?.data))
				.catch();
			this.addModalToCurrentNavigateObject(modal);
			await modal.present();
		});
	}

	/**
	 * A method that opens a popover based on a component
	 * @param _component The component to open as popover
	 * @param _clickEvent The click event to attach the popover to
	 * @param _componentProps Extra properties to pass to the component
	 * @returns A promise that returns data returned from the component
	 */
	private async openPopover<Return>(
		_component: any,
		_clickEvent?: PointerEvent,
		_componentProps: any = {}
	): Promise<Return> {
		return new Promise(async (resolve) => {
			const popover = await this.popoverController.create({
				component: _component,
				event: _clickEvent,
				backdropDismiss: false,
				componentProps: _componentProps,
			});
			void popover
				.onDidDismiss()
				.then((result) => resolve(result?.data))
				.catch();
			this.addPopoverToCurrentNavigateObject(popover);
			await popover.present();
		});
	}

	/**
	 * A method to add data to the navigation data, using the {@link NavigationDataManager}
	 * @param _data The data to add to the navigation data
	 */
	private addToNavigationData(_data?: { [key in NavigationDataKey]?: any }): void {
		if (_data) {
			Object.keys(_data).forEach((key) => {
				this.navigationDataManager.addData(key as NavigationDataKey, _data[key]);
			});
		}
	}

	/**
	 * A method to get the current (active) navigation object
	 * @returns The current (active) navigation object
	 */
	private getCurrentNavigationObject(): INavigationObject {
		return this.history[this.history.length - 1];
	}

	/**
	 * A method that adds the popover instance to the navigation object that created the popover
	 * @param _popover The popover instance
	 */
	private addPopoverToCurrentNavigateObject(_popover: HTMLIonPopoverElement): void {
		(this.history[this.history.length - 1].popoverInstance as HTMLIonPopoverElement) = _popover;
	}

	/**
	 * A method that adds the modal instance to the navigation object that created the modal
	 * @param _modal The modal instance
	 */
	private addModalToCurrentNavigateObject(_modal: HTMLIonModalElement): void {
		(this.history[this.history.length - 1].modalInstance as HTMLIonModalElement) = _modal;
	}

	private isPrefixRoute(type: NavigationType, destination: string): boolean {
		if (type === NavigationType.page) {
			const prefixesRegex = Object.values(RoleNavigationPrefix)
				.map((prefix) => `/${prefix}`)
				.join('|');
			return cloneDeep(destination).replace(new RegExp(prefixesRegex), '').length === 0;
		}
		return false;
	}

	/**
	 * Add the correct prefix (internal, client, partner) to the url
	 * @param _type The navigation type
	 * @param _destination The url before adding the prefix
	 * @returns {string} the role prefix
	 */
	private addRolePrefix(_type: NavigationType, _destination: string): string {
		if (_type === NavigationType.page) {
			let prefix: string;
			switch (this.userManager.getRole()) {
				case ROLE.SOCIAL_PARTNER:
				case ROLE.PARTNER:
					prefix = RoleNavigationPrefix.Partner;
					break;
				case ROLE.CLIENT:
					prefix = RoleNavigationPrefix.Client;
					break;
				case ROLE.CONSULTANT:
					prefix = RoleNavigationPrefix.Consultant;
					break;
				case ROLE.DISPATCH:
				case ROLE.ADMIN:
				case ROLE.SUPER_ADMIN:
					prefix = RoleNavigationPrefix.Admin;
					break;
			}

			if (prefix) {
				if (isArray(_destination)) {
					if (_destination[0] !== prefix && _destination[1] !== prefix) {
						_destination = `/${prefix}/${_destination.map((destination) => destination.replace('/', '')).join('/')}`;
					}
				}
				if (isString(_destination)) {
					if (_destination?.split('/')[0] !== prefix && _destination?.split('/')[1] !== prefix) {
						_destination = `/${prefix}${_destination}`;
					}
				}
			}
		}
		return _destination;
	}
}
