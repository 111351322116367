import { IACL } from './interfaces';
import { ROLE } from './enums';
import { isEmpty } from 'lodash';

export const ACL: IACL = {
	partner: {
		roles: [ROLE.SOCIAL_PARTNER, ROLE.PARTNER],
		routes: {
			dashboard: ['*'],
			'boek-opdracht': ['*'],
			opdrachten: ['*'],
			hulpmiddelen: ['*'],
			digipunten: ['*'],
			vormingen: ['*'],
			profielen: ['*'],
			gebruikers: ['*'],
			statistieken: ['*'],
		},
	},
	admin: {
		roles: [ROLE.DISPATCH, ROLE.ADMIN, ROLE.SUPER_ADMIN],
		routes: {
			profile: ['*'],
			ratings: ['*'],
			calendar: ['*'],
			workorders: ['*'],
			requests: ['*'],
			digipoints: ['*'],
			digipoint: ['*'],
			'create-appointment': ['*'],
			clients: ['*'],
			client: ['*'],
			employees: ['*'],
			employee: ['*'],
			candidate: ['*'],
			administration: ['*'],
			tools: ['*'],
			statistics: ['*'],
			payrolling: ['*'],
			partner: ['*'],
			partners: ['*'],
			chats: ['*'],
			'topic-package-template': ['*'],
		},
	},
	consultant: {
		roles: [ROLE.CONSULTANT],
		routes: {
			profile: ['*'],
			digipoints: ['*'],
			calendar: ['*'],
			workorders: ['*'],
			client: ['*'],
			status: ['*'],
			ratings: ['*'],
			chat: ['*'],
		},
	},
};

/**
 * Validate a route & user role against the ACL
 * @param _route The route the user want's to navigate to
 * @param _role The role of the user
 * @returns Whether the user is allowed or not
 */
export const validateACL = (_route: string, _role: ROLE): boolean => {
	const routes = _route.split('/').filter((route) => !isEmpty(route));
	if (routes.length > 1) {
		/**
		 * Validate if user role has access to parent route
		 */
		const acl = ACL[routes[0]];

		const allowedRoles = acl?.roles ?? [];
		if (!allowedRoles.includes('*') && !allowedRoles.includes(_role)) {
			return false;
		}

		/**
		 * Validate if user role has access to actual (child) route
		 */
		const allowedRolesForChild = acl.routes[routes[1]] || [];
		if (!allowedRolesForChild.includes('*') && !allowedRolesForChild.includes(_role)) {
			return false;
		}
	}
	return true;
};
